import { mlAuto, mrAuto } from 'assets/jss/material-kit-pro-react.jsx';
import arrow from 'ps-assets/img/senior-citizen-arrow.png';
import hospitalize from 'ps-assets/img/seniorcitizen/hospitalization.png';
import hoverhospitalize from 'ps-assets/img/seniorcitizen/hoverhospitalization.png';
import settlements from 'ps-assets/img/seniorcitizen/settlements.png';
import hoversettlements from 'ps-assets/img/seniorcitizen/hoversettlements.png';
import hospitals from 'ps-assets/img/seniorcitizen/hospitals.png';
import hoverhospitals from 'ps-assets/img/seniorcitizen/hoverhospitals.png';
const googleSeniorCitizenStyles = function(theme) {
  return {
    topStrip: {
      width: `100%`,
      backgroundColor: `#2980b9`,
      height: `6px`,
    },
    whiteBg: {
      backgroundColor: `white`,
      [theme.breakpoints.down(`md`)]: {
        marginLeft: `unset`,
        marginRight: `unset`,
      },
      [theme.breakpoints.down(`sm`)]:{
        width: `auto`
      }
    },
    otpNumber: {
      paddingTop: '2%',
      paddingBottom: '1%',
    },
    classicon1: {
      width: '100%',
      height: 'auto',
      backgroundImage: `url(${hospitalize})`,
      textAlign: 'right',
      paddingBottom: '0px',
      backgroundSize: '63px 55px',
      backgroundRepeat: 'no-repeat',
      '&:hover ': {
        backgroundImage: `url(${hoverhospitalize})`,
        backgroundRepeat: 'no-repeat',
      },
    },
    classicon2: {
      width: '100%',
      backgroundImage: `url(${settlements})`,
      textAlign: 'right',
      paddingBottom: '0px',
      backgroundSize: '63px 55px',
      backgroundRepeat: 'no-repeat',
      '&:hover ': {
        backgroundImage: `url(${hoversettlements})`,
        backgroundRepeat: 'no-repeat',
      },
    },
    modalButton: {
      marginLeft: '30px',
    },
    classicon3: {
      width: '100%',
      height: 'auto',
      backgroundImage: `url(${hospitals})`,
      textAlign: 'right',
      paddingBottom: '0px',
      backgroundSize: '63px 55px',
      backgroundRepeat: 'no-repeat',
      '&:hover ': {
        backgroundImage: `url(${hoverhospitals})`,
        backgroundRepeat: 'no-repeat',
      },
    },
    customContainer: {
      width: `100%`,
      [theme.breakpoints.up(`lg`)]: {
        width: `70%`,
        ...mlAuto,
        ...mrAuto,
      },
    },
    header: {
      display: `inline-flex`,
      width: `100%`,
      paddingRight: `3px`,
      paddingLeft: `3px`,
      [theme.breakpoints.down(`xs`)]:{
        display: `block`
      }
    },
    tagLine: {
      width: `inherit`,
      fontFamily: `'Open Sans', sans-serif`,
      "& p": {
        fontWeight: `300`,
        fontSize: `35px`,
        lineHeight: `100px`,
        color: `#c85347`,
        textAlign: `left`,
        '& span': {
          color: `#2980b9`,
        },
      },
      [theme.breakpoints.down(`sm`)]:{
        "& p":{
          fontSize: `30px`,
          textAlign: `center`,
        }
      },
      [theme.breakpoints.down(`xs`)]:{
        "& p":{
          fontSize: `100%`,
          textAlign: `center`,
          lineHeight: `24px`,
          fontWeight: `bold`
        }
      }
    },
    carouselContainer:{
      [theme.breakpoints.down(`sm`)]:{
        marginLeft: `auto`,
        marginRight: `auto`
      }
    },
    carouselImages: {
      width: `inherit`,
    },
    highlightsContainer: {
      width: `inherit`,
      display: `flex`,
      padding: `0px 10px`,
      [theme.breakpoints.up(`md`)]:{
        padding: `0px`,
      },
      [theme.breakpoints.down(`xs`)]:{
        display: `block`
      }
    },
    cashlessHospDetail: {
      [theme.breakpoints.up(`md`)]: {
        marginLeft: `0px !important`
      }
    },
    networkHospDetail:{
      [theme.breakpoints.up(`md`)]:{
        marginRight: `0px !important`,
      }
    },
    detail: {
      width: `33%`,
      backgroundColor: `#eee`,
      marginLeft: `1%`,
      marginRight: `1%`,
      display: `flex`,
      '&:hover': {
        backgroundColor: `#2980b9`,
        transitionDuration: `0.5s`,
        '& p': {
          color: `white`,
        },
      },
      '& p': {
        '@media (max-width: 1280px)': {
          fontSize: `15px`,
          fontWeight: `400`,
          paddingLeft: '2%',
        },
        '@media (max-width: 968px)': {
          fontSize: `18px`,
          fontWeight: `400`,
        },
        color: `#2980b9`,
        marginBottom: `0px`,
        paddingBottom: `3px`,
        paddingTop: '1px',
        fontSize: `18px`,
        fontWeight: `400`,

        '& span': {
          marginLeft: `1%`,
          marginRight: `1%`,
          fontSize: `18px`,
        },
      },
      [theme.breakpoints.down(`xs`)]:{
        marginLeft: `2%`,
        width: `auto`,
        marginTop: `5%`
      }
    },
    details: {
      width: `100%`,
      paddingLeft:'23%',
      textAlign:'left',
      [theme.breakpoints.down(`sm`)]:{
        paddingLeft:'22%',
      }
    },
    detailsCashless: {
      width: `100%`,
      paddingLeft:'23%',
      marginTop:'4.5%',
      paddingBottom:'1.5%',
      textAlign:'left'
    },
    detailIcon: {
      margin: `0% 5%`,
    },
    megaContainer: {
      display: `flex`,
      padding: `1% 1% 0% 1%`,
      [theme.breakpoints.down(`xs`)]: {
        paddingLeft: `0px`,
        display: `block`
      }
    },
    content: {
      width: `66%`,
      '& p': {
        color: `#2980b9`,
        fontSize: `12px`,
        lineHeight: `20px`,
        marginBottom: `10px`,
        fontWeight: `400`,
        [theme.breakpoints.down(`sm`)]:{
          fontSize: `18px`,
          lineHeight: `22px`,
          fontFamily: `'Open Sans', sans-serif`,
          fontWeight: `unset`,
          marginTop: `15px`,
          marginBottom: `15px`,
        }
      },
      "& h1": {
        fontFamily: `'Open Sans', sans-serif`,
        fontSize: `28px`,
        fontWeight: `400`,
        color: `#c0392b`,
        marginBottom: `10px`,
        [theme.breakpoints.down(`sm`)]:{
          fontSize: `30px`,
        }
      },
      '& ul': {
        listStyle: `none`,
        marginBottom: `0px`,
        paddingInlineStart: `20px`,
        "& li": {
          fontFamily: `'Open Sans', sans-serif`,
          listStyleImage: `url(${arrow})`,
          lineHeight: `20px`,
          color: `#2980b9`,
          [theme.breakpoints.up(`md`)]:{
            fontSize: `12px`,
            fontWeight: `400`
          },
          [theme.breakpoints.down(`sm`)]: {
            fontSize: `100%`,
            lineHeight: `20px`,
            fontWeight: `400`,
            marginBottom: `5px`
          }
        },
      },
      [theme.breakpoints.down(`xs`)]:{
        width: `auto`,
        padding: `0px 1%`
      }
    },
    formContainer: {
      width: `33%`,
      paddingTop: `20px`,
      backgroundColor: `#eee`,
      paddingBottom: `10px`,
      maxWidth: `360px`,
      position: `relative`,
      top: `15px`,
      [theme.breakpoints.up(`md`)]:{
        marginLeft: `auto`,
        maxWidth: `100% !important`,
      },
      [theme.breakpoints.down(`sm`)]: {
        height: `fit-content`,
        width: `auto`,
        ...mlAuto,
        ...mrAuto,
        marginLeft: `1%`,
        marginRight: '1%',
        marginBottom: '8%',
        maxWidth: `100% !important`,
      },
      [theme.breakpoints.down(`xs`)]: {
        marginLeft: `1%`,
        marginRight: '1%',
        marginBottom: '8%',
        maxWidth: `100% !important`,
      }
    },
    textBoxContainer: {
      display: `flex`,
    },
    label: {
      textAlign: `right`,
      width: `20%`,
      fontFamily: `'Open Sans', sans-serif`,
      "& label": {
        color: `#666`,
      },
      [theme.breakpoints.down(`sm`)]: {
        height: `fit-content`,
        marginTop: `auto`,
        marginBottom: `auto`
      }
    },
    textBox: {
      padding: `5px 0px`,
      paddingLeft: `12px`,
      width: `80%`,
      '& input': {
        width: `inherit`,
        height: `35px`,
      },
      '& select': {
        height: `35px`,
        width: `inherit`,
        paddingLeft: `12px`,
      },
      '& p': {
        color: `red`,
      },
      [theme.breakpoints.down(`sm`)]:{
        width : `90%`,
        padding: `4px 0px`,
        paddingLeft: `13px`
      }
    },
    privacyPolicy: {
      fontFamily: `'Open Sans', sans-serif`,
      width: `80%`,
      [theme.breakpoints.up(`md`)]:{
        "& p":{
          fontSize: `10px`,
          lineHeight: `10px`
        }
      },
      [theme.breakpoints.down(`sm`)]: {
        "& p":{
          lineHeight: `13px`
        }
      }
    },
    submitButton: {
      width: `80%`,
      display: `flex`,
      '& input': {
        background: `#c0392b`,
        border: `none`,
        height: `30px`,
        color: `#fff`,
        marginTop: `10px`,
        position: `relative`,
        fontSize: `16px`,
        fontWeight: `300`,
        textTransform: `uppercase`,
        cursor: `pointer`,
        ...mlAuto,
        marginRight: `5%`,
      },
    },
    conditionsApply: {
      color: `#2980b9`,
      fontSize: `12px`,
      lineHeight: `20px`,
      marginBottom: `10px`,
      textAlign: `right`,
      [theme.breakpoints.down(`sm`)]: {
        marginBottom: `0px`,
      }
    },
    quote: {
      backgroundColor: `#2980b9`,
      padding: `10px 0px`,
      color: `#fff`,
      marginBottom: `20px`,
      textAlign: `center`,
      fontSize: `25px`,
      '& p': {
        fontSize: `100%`,
      },
      [theme.breakpoints.down(`sm`)]: {
        fontSize: `16px`,
        marginBottom: `0px`
      }
    },
    subFooter: {
      marginTop: `2%`,
      [theme.breakpoints.down(`sm`)]:{
        marginTop: `5%`,
      }
    },
    footer: {
      fontFamily: `'Open Sans', sans-serif`,
      textAlign: `center`,
      backgroundColor: `#949494`,
      color: `#fff`,
      paddingTop: `15px`,
      paddingBottom: `15px`,
      fontSize: `11px`,
      '& p': {
        marginBottom: `0px`,
        fontSize: `100%`,
      },
      '& a': {
        color: `white`,
      },
      [theme.breakpoints.down(`sm`)]: {
        paddingBottom: `0px`,
        "& p":{
          fontSize: `13px`,
        }
      }
    },
  };
};

export default googleSeniorCitizenStyles;
