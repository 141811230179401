import React from "react";
import InstantBuyLayout from "ps-components/Layout/InstantBuyLayout";
import PropTypes from "prop-types";
import classNames from "classnames";
import root from "window-or-global";
import Carousel from "react-slick";
import withStyles from "@material-ui/core/styles/withStyles";
import ModalForm from "ps-components/ModalForm/ModalForm";
import googleSeniorCitizenStyles from "ps-assets/jss/GoogleSeniorCitizenStyle.jsx";
import carouselImage1 from "ps-assets/img/google-senior-citizen-image1.jpg";
import carouselImage2 from "ps-assets/img/google-senior-citizen-image2.jpg";
import starLogo from "ps-assets/img/detailed-logo.jpg";
import { landingPageLMSCall } from "util/lmsRequest";
import {  browserWindow,browserDocument } from "util/localStorageHelper";
const HOSPITALCOUNT = process.env.HOSPITALCOUNT;

const validationConditions = {
  name: `^[a-zA-Z .]*$`,
  mobile: `^[6-9][0-9]{9}$`,
  pincode: `^[1-9][0-9]{5}$`,
};

const carouselImages = [carouselImage1, carouselImage2];

const CUSTOMER_PORTAL_API_URL = process.env.CUSTOMER_PORTAL_API_URL;

const benefitsList = {
  nonOtp: {
    data: [
      {
        text: "For Senior citizens aged between 60 to 75 years *",
      },
      {
        text: "No pre-insurance medical test required.",
      },
      {
        text: "Covers pre-existing diseases from the second year onwards.",
      },
      {
        text: "Guaranteed renewals beyond second year.",
      },
      {
        text: "Avail Higher Sum Insured Coverage upto 10 Lakhs at affordable cost",
      },
    ],
    footer: <p>
      For further details visit{" "}
      <a href="http://www.starhealth.in">www.starhealth.in</a> |
    SHAI/17-18/Online/LP/BuyOnline/011
  </p>
  },
  otp: {
    data: [
      {
        text: "For Senior citizens aged between 60 to 75 years *",
      },
      {
        text: "No pre-insurance medical test required.",
      },
      {
        text: "Covers all pre-existing diseases from the first year, except those which were diagnosed in the preceding year to the date of proposal.",
      },
      {
        text: "Diseases which were diagnosed in the preceding year to the date of proposal will be covered from second year onwards.",
      },
      {
        text: "Guaranteed renewals beyond second year.",
      },
      {
        text: "Avail Higher Sum Insured Coverage upto 5 Lakhs at affordable cost.",
      },
    ], 
    footer: <p>
      For further details visit
      <a href="http://www.starhealth.in">www.starhealth.in</a> |
      SHAI/13-14/Online/Brand/001
  </p>
  }
};

const carouselSettings = {
  dots: true,
  infinite: true,
  arrows: false,
  autoplaySpeed: 3000,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  dotsClass: `vertical-dots`,
  adaptiveHeight: true,
};
class GoogleSeniorCitizen extends React.Component {
  state = {
    name: ``,
    email: ``,
    mobile: ``,
    pincode: ``,
    privacyPolicy: false,
    submitButton: false,
    showOTP: false,
    otpNumber: ``,
    otpError: ``
  };

  componentDidMount = () => {
    browserDocument.body.style.backgroundColor = `white`;
  };

  handleChange = (e) => {
    switch (e.target.id) {
      case `name`:
          this.setState({ [e.target.id]: e.target.value });
        break;
      case `pincode`:
        if (!isNaN(e.target.value) && e.target.value.length <= 6) {
          this.setState({ [e.target.id]: e.target.value });
        }
        break;
      case `mobile`:
        if (!isNaN(e.target.value) && e.target.value.length <= 10) {
          this.setState({ [e.target.id]: e.target.value });
        }
        break;
      case `otpNumber`:
        if (!isNaN(e.target.value) && e.target.value.length <= 6) {
          this.setState({ [e.target.id]: e.target.value, otpError: `` });
        }
        break;
      default:
        this.setState({ [e.target.id]: e.target.value });
        break;
    }
  };

  handlePrivacyPolicy = () => {
    this.setState({
      privacyPolicy: !this.state.privacyPolicy,
    });
  };

  sendToLMS = async () => {
    try {
      let queryParams = decodeURIComponent(browserWindow.location.search);
      queryParams = queryParams.split(`?`)[1];
      let response = await landingPageLMSCall(
        this.state.name,
        this.state.mobile,
        this.state.email,
        this.state.pincode
      );
      response = await response.json();
      sessionStorage.setItem(`prevLandingPage`, `complete-health-insurance`);
      root.open(
        `${browserWindow.location.pathname}/thank-you?${
          browserWindow.location.pathname.includes('otp') ? `otp=yes&` : ``
        }userId=${
          response.zohoEntityId ? response.zohoEntityId : ``
        }${queryParams ? `&${queryParams}` : ``}`,
        `_self`
      );
    } catch (exception) {
      console.error(`except - `, exception);
    }
  };
  generateOTP = () => {
    let generateOTP_API = `${CUSTOMER_PORTAL_API_URL}/api/services/authentication/token/generateOtp?mobileNo=${this.state.mobile}&serviceType=prelogin`;
    fetch(generateOTP_API).then(res => {
      if(res.status === 200){
        this.setState({
          showOTP:true
        });
      }
      else{
        this.setState({
          otpError:`Invalid Mobile Number`
        });
      }
    }).catch(() => this.setState({
      otpError:`Network Problem. Please try again later`
    }))
  }
  validateOTP = async () => {
    let validateOTP_API = `${CUSTOMER_PORTAL_API_URL}/api/services/authentication/token/validateOtp?mobileNo=${this.state.mobile}&otp=${this.state.otpNumber}`;
    fetch(validateOTP_API).then(async res => {
      if(res.status === 200){
        this.sendToLMS(`senior-citizen-otp`);
      }
      else{
        this.setState({
          otpError : `Invalid OTP`
        });
      }
    }).catch(() => {
      this.setState({
        otpError: `Network Problem. Please try again later`
      })
    })
  }

  proceed = () => {
    switch(this.props.pageContext.source){
      case `senior-citizen-otp`:
        this.generateOTP();
        break;
      default :
        this.sendToLMS(`complete-health-insurance`);
        break;
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      otpError: ``,
      submitButton: true,
    });
    if (this.state.name !== ``) {
      if (this.state.email.search(/^\S+@\S+\.\S+$/) === 0) {
        if (this.state.mobile.search(validationConditions.mobile)==0) {
          if (this.state.privacyPolicy) {
            if (this.state.pincode.search(validationConditions.pincode)==0) {
              this.sendToLMS();
            }
          }
        }
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <InstantBuyLayout noHeader={true} noFooter={true}>
        <div className={classes.whiteBg}>
        <ModalForm
            title=""
            show={this.state.showOTP}
            customStyle={`freeQuote`}
            handleClose={() => this.setState({showOTP:false,otpNumber:``})}
          >
            <div>
              <p style={{fontWeight:"bold",margin:"15px 0px"}}>STAR Health - OTP Validation</p>
              <p>
                We have sent the OTP details to the Mobile number mentioned in
                the form. Please key-in this number to proceed further.
              </p>
              <p style={{fontStyle:"italic"}}>
                Kindly wait for 2 minutes after which you can use the Resend
                button to request OTP again.
              </p>
              <div className={classNames(classes.otpNumber,classes.mlAuto,classes.mrAuto)}>
                <p>Enter OTP:</p>
                <input
                  type="text"
                  name="otpNumber"
                  id="otpNumber"
                  value={this.state.otpNumber}
                  onChange={this.handleChange}
                />
              </div>
              <div className={classNames(classes.otpNumber,classes.mlAuto,classes.mrAuto)}>
                <button className={classes.modalButton} type="button" onClick={this.validateOTP}>Proceed</button>
                <button className={classes.modalButton} type="button" onClick={this.generateOTP}>Resend OTP</button>
              </div>
              <p style={{marginTop:`10px`,color:`red`,textAlign:`center`}}>{this.state.otpError}</p>
            </div>
          </ModalForm>
          <div className={classes.topStrip}></div>
          <div className={classNames(classes.customContainer)}>
            <div className={classes.header}>
              <div>
                <img src={starLogo} alt="google-senior-image" />
              </div>
              <div className={classes.tagLine}>
                <p>
                  <span>Senior Citizen -</span> Red Carpet Insurance
                </p>
              </div>
            </div>
            <div className={classes.carouselContainer}>
              <Carousel {...carouselSettings}>
                {carouselImages.map((image, key) => (
                  <div key={key + 1}>
                    <img
                      className={classes.carouselImages}
                      src={image}
                      alt={`image-${key + 1}`}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
            <div>
              <div className={classes.highlightsContainer}>
                <div
                  className={classNames(classes.detail,classes.cashlessHospDetail)}
                  key="Cashless hospitalization"
                >
                  <p className={classes.classicon1}>
                  <div className={`${classes.detailsCashless}`}>
                    <p>Cashless hospitalization</p>
                  </div>
                  </p>
                </div>
                <div
                  className={classes.detail}
                  key="Faster and Hasslefree Settlements"
                >
                  <p className={classes.classicon2}>
                  <div className={`${classes.details}`}>
                    <p>Faster and Hasslefree Settlements</p>
                  </div>
                  </p>
                </div>
                <div
                  className={classNames(classes.detail,classes.networkHospDetail)}
                  key={HOSPITALCOUNT}
                >
                  <p className={classes.classicon3}>
                  <div className={`${classes.details}`}>
                    <p>Network with {HOSPITALCOUNT} Hospitals in India</p>
                  </div>
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.megaContainer}>
              <div className={classes.content}>
                <p>
                  Gift your parents a healthy retirement at their old age. Star
                  health Senior Citizen policy is intended for the elderly care
                  since it is the time for them to start fresh. Elders can
                  experience an absolute security with the policy. We unfold our
                  red carpet to welcome old age.
                </p>
                <h1>Benefits of Red Carpet</h1>
                <ul>
                {benefitsList[
                    this.props.pageContext.source === `senior-citizen-otp`
                      ? `otp`
                      : `nonOtp`
                  ].data.map((benefit, key) => (
                    <li key={key + 1}>{benefit.text}</li>
                  ))}
                </ul>
              </div>
              <div className={classes.formContainer}>
                <form onSubmit={this.handleSubmit}>
                  <div className={classes.textBoxContainer}>
                    <div className={classes.label}>
                      <label>Name:</label>
                    </div>
                    <div className={classes.textBox}>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                      />
                      {this.state.submitButton && this.state.name === `` ? (
                        <p>Please put your full name.</p>
                      ) : (
                        this.state.submitButton &&
                        this.state.name.length < 3 && <p>Enter Full Name.</p>
                      )}
                    </div>
                  </div>
                  <div className={classes.textBoxContainer}>
                    <div className={classes.label}>
                      <label>Email:</label>
                    </div>
                    <div className={classes.textBox}>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                      {this.state.submitButton && this.state.email === `` ? (
                        <p>Please Enter your Email.</p>
                      ) : (
                        this.state.submitButton &&
                        this.state.email.search(/^\S+@\S+\.\S+$/) !== 0 && (
                          <p>Please enter a valid email address.</p>
                        )
                      )}
                    </div>
                  </div>
                  <div className={classes.textBoxContainer}>
                    <div className={classes.label}>
                      <label>Mobile:</label>
                    </div>
                    <div className={classes.textBox}>
                      <input
                        type="text"
                        id="mobile"
                        name="mobile"
                        value={this.state.mobile}
                        onChange={this.handleChange}
                      />
                      {this.state.submitButton && this.state.mobile === `` ? (
                        <p>Enter Contact Number.</p>
                      ) : (((this.state.submitButton && this.state.mobile.length < 10) || (this.state.submitButton && this.state.mobile.search(`^[6-9][0-9]{9}$`) == -1)) && (
                          <p>Enter 10 Digit Number.</p>
                        )
                      )}
                    </div>
                  </div>
                  <div className={classes.textBoxContainer}>
                    <div className={classes.label}>
                      <label>PinCode:</label>
                    </div>
                    <div className={classes.textBox}>
                      <input
                        type="text"
                        id="pincode"
                        name="pincode"
                        value={this.state.pincode}
                        onChange={this.handleChange}
                      />
                      {this.state.submitButton && this.state.pincode === `` ? (
                        <p>Enter PinCode.</p>
                      ) : (
                        ((this.state.submitButton && this.state.pincode.length < 6) || (this.state.submitButton && this.state.pincode.search(`^[1-9][0-9]{5}$`) == -1)) && (
                          <p>Enter 6 Digit PinCode.</p>
                        )
                      )}
                    </div>
                  </div>
                  <div className={classes.textBoxContainer}>
                    <div className={classes.label}></div>
                    <div className={classes.privacyPolicy}>
                      <p>
                        <input
                          type="checkbox"
                          onClick={this.handlePrivacyPolicy}
                          checked={this.state.privacyPolicy}
                        />
                        I have read and agree to the
                        <a
                          href="/static-privacy-policy"
                          target="_blank"
                          style={{
                            cursor: `pointer`,
                            textDecoration: "underline",
                            margin: `0px 3px`,
                          }}
                          rel={`noopener noreferrer`}
                        >
                          Privacy Policy
                        </a>
                        I authorize Star Health Insurance to contact me via
                        email or phone or SMS.
                      </p>
                      {this.state.submitButton && !this.state.privacyPolicy && (
                        <div className={classes.textBox}>
                          <p>Please Agree to the Terms</p>
                        </div>
                      )}
                      {this.state.otpError !== `` && (
                        <p style={{ color: `red`, textAlign: `center` }}>
                          {this.state.otpError}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={classes.textBoxContainer}>
                    <div className={classes.label}></div>
                    <div className={classes.submitButton}>
                      <input type="submit" value="Submit" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className={classes.subFooter}>
              <p className={classes.conditionsApply}>* Conditions Apply.</p>
              <div className={classes.quote}>
                <p>
                  <i className="fa fa-quote-left  fa-2x"></i>
                  Take pride in getting old..Its time to start living entirely
                  for you.
                  <i className="fa fa-quote-right fa-2x"></i>
                </p>
              </div>
            </div>
          </div>
          <div className={classes.footer}>
            <div className={classes.customContainer}>
              <p>IRDAI REGISTRATION NUMBER. 129</p>
              <div>
                For complete details on coverage, terms, conditiions and
                exclusions, please read the Policy document carefully. Insurance
                is the subject matter of solicitation.
              </div>
              <p>
                <b>Star Health and Allied Insurance Company Limited. </b>
              </p>
              <p>
                Corporate Office: No.1, New Tank Street, Valluvar Kottam High
                Road, Nungambakkam, Chennai - 600034. Sales & Services - 044 4674 5800
              </p>
              {
                benefitsList[this.props.pageContext.source === `senior-citizen-otp`
                  ? `otp`
                  : `nonOtp`].footer
              }
            </div>
          </div>
        </div>
      </InstantBuyLayout>
    );
  }
}

GoogleSeniorCitizen.defaultProps = {
  pageContext: {
    source: ``
  }
}

GoogleSeniorCitizen.propTypes = {
  classes: PropTypes.object,
  pageContext: PropTypes.object
};

export default withStyles(googleSeniorCitizenStyles)(GoogleSeniorCitizen);
